{
  "baseFooter": {
    "rights": "Todos os direitos reservados"
  },
  "baseHeader": {
    "menu": {
      "account": "A minha conta",
      "language": "Idioma: {currentLanguage}",
      "logout": "Sair",
      "manage_following": "A seguir",
      "my_clips_and_highlights": "Meus clipes/destaques",
      "select_language": "Selecionar idioma",
      "selected_videos": "Vídeos guardados"
    }
  },
  "button": {
    "back": "retroceder",
    "cancel": "Cancelar",
    "clear_all": "Limpar tudo",
    "confirm": "Confirmar",
    "delete": "Excluir",
    "edit": "Editar",
    "fullscreen_enter": "Ecrã inteiro",
    "fullscreen_exit": "Sair do ecrã inteiro",
    "refresh": "Atualizar",
    "remove": "Remover",
    "toggle_dark": "Modo escuro",
    "toggle_langs": "Alterar idiomas",
    "buy_now": "Compre Agora",
    "enter_another_card": "Digite outro cartão",
    "try_again": "Tente novamente",
    "enter_another_code": "Digite outro código",
    "submit_code": "Enviar código",
    "download": "Download",
    "no": "Não",
    "go_to_homepage": "Vá para a página inicial",
    "slide_back": "Slide anterior",
    "slide_forward": "Próximo slide",
    "exit": "Saída",
    "full": "Completo",
    "regular": "Regular",
    "rename": "Mudar nome",
    "get": "Aqui"
  },
  "common": {
    "data_not_found": "O servidor não consegue encontrar os dados pretendidos ou não está a responder. \nPor favor, tente novamente mais tarde.",
    "empty_list": "A lista está vazia. \nVolte mais tarde.",
    "empty_search": "Não foram encontrados resultados para \"{searchValue}\".",
    "loading": "A carregar...",
    "loading_failed": "Falha ao carregar",
    "empty_events_list": "Ainda não há eventos",
    "get_mobile_app": "Descarrega a app do \n{appName}"
  },
  "countries": {
    "AD": "Andorra",
    "AE": "Emirados Árabes Unidos",
    "AF": "Afeganistão",
    "AG": "Antígua e Barbuda",
    "AI": "Anguila",
    "AL": "Albânia",
    "AM": "Arménia",
    "AO": "Angola",
    "AR": "Argentina",
    "AS": "Samoa Americana",
    "AT": "Áustria",
    "AU": "Austrália",
    "AW": "Aruba",
    "AZ": "Azerbaijão",
    "BA": "Bósnia e Herzegovina",
    "BB": "Barbados",
    "BD": "Bangladesh",
    "BE": "Bélgica",
    "BF": "Burkina Faso",
    "BG": "Bulgária",
    "BH": "Bahrein",
    "BI": "Burundi",
    "BJ": "Benim",
    "BM": "Bermudas",
    "BN": "Brunei",
    "BO": "Bolívia",
    "BR": "Brasil",
    "BS": "Bahamas",
    "BT": "Butão",
    "BW": "Botsuana",
    "BY": "Bielorrússia",
    "BZ": "Belize",
    "CA": "Canadá",
    "CF": "República Centro-Africana",
    "CG": "Congo",
    "CH": "Suíça",
    "CI": "Costa do Marfim",
    "CK": "Ilhas Cook",
    "CL": "Chile",
    "CM": "Camarões",
    "CN": "China",
    "CO": "Colômbia",
    "CR": "Costa Rica",
    "CU": "Cuba",
    "CV": "Cabo Verde",
    "CW": "Curaçao",
    "CX": "Ilha do Natal",
    "CY": "Chipre",
    "CZ": "Chéquia",
    "DE": "Alemanha",
    "DJ": "Djibuti",
    "DK": "Dinamarca",
    "DM": "Dominica",
    "DO": "República Dominicana",
    "DZ": "Argélia",
    "EC": "Equador",
    "EE": "Estónia",
    "EG": "Egito",
    "ER": "Eritreia",
    "ES": "Espanha",
    "ET": "Etiópia",
    "FI": "Finlândia",
    "FJ": "Fiji",
    "FK": "Ilhas Malvinas",
    "FM": "Micronésia",
    "FO": "ilhas Faroe",
    "FR": "França",
    "GA": "Gabão",
    "GD": "Granada",
    "GE": "Geórgia",
    "GF": "Guiana Francesa",
    "GH": "Gana",
    "GI": "Gibraltar",
    "GL": "Gronelândia",
    "GM": "Gâmbia",
    "GN": "Guiné",
    "GP": "Guadalupe",
    "GQ": "Guiné Equatorial",
    "GR": "Grécia",
    "GS": "Ilhas Geórgia do Sul e Sandwich do Sul",
    "GT": "Guatemala",
    "GU": "Guam",
    "GW": "Guiné-Bissau",
    "GY": "Guiana",
    "HK": "Hong Kong",
    "HN": "Honduras",
    "HR": "Croácia",
    "HT": "Haiti",
    "HU": "Hungria",
    "ID": "Indonésia",
    "IE": "Irlanda",
    "IL": "Israel",
    "IN": "Índia",
    "IO": "Território Britânico do Oceano Índico",
    "IQ": "Iraque",
    "IR": "Irão",
    "IS": "Islândia",
    "IT": "Itália",
    "JM": "Jamaica",
    "JO": "Jordânia",
    "JP": "Japão",
    "KE": "Quênia",
    "KG": "Quirguistão",
    "KH": "Camboja",
    "KI": "Kiribati",
    "KM": "Comores",
    "KP": "Coreia do Norte",
    "KR": "Coreia do Sul",
    "KW": "Kuwait",
    "KY": "Ilhas Caimão",
    "KZ": "Cazaquistão",
    "LA": "Laos",
    "LB": "Líbano",
    "LI": "Liechtenstein",
    "LK": "Sri Lanka",
    "LR": "Libéria",
    "LS": "Lesoto",
    "LT": "Lituânia",
    "LU": "Luxemburgo",
    "LV": "Letónia",
    "LY": "Líbia",
    "MA": "Marrocos",
    "MC": "Mónaco",
    "MD": "Moldávia",
    "ME": "Montenegro",
    "MG": "Madagáscar",
    "MH": "Ilhas Marshall",
    "MK": "Macedónia",
    "ML": "Mali",
    "MM": "Mianmar",
    "MN": "Mongólia",
    "MO": "Macau RAE da China",
    "MP": "Ilhas Marianas do Norte",
    "MQ": "Martinica",
    "MR": "Mauritânia",
    "MS": "Monserrate",
    "MT": "Malta",
    "MU": "Maurício",
    "MV": "Maldivas",
    "MW": "Malawi",
    "MX": "México",
    "MY": "Malásia",
    "NA": "Namíbia",
    "NC": "Nova Caledónia",
    "NE": "Níger",
    "NF": "Ilha Norfolk",
    "NG": "Nigéria",
    "NI": "Nicarágua",
    "NL": "Países Baixos",
    "NO": "Noruega",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "Nova Zelândia",
    "OM": "Omã",
    "PA": "Panamá",
    "PE": "Peru",
    "PF": "Polinésia Francesa",
    "PG": "Papua Nova Guiné",
    "PH": "Filipinas",
    "PK": "Paquistão",
    "PL": "Polónia",
    "PR": "Porto Rico",
    "PS": "Território Palestino",
    "PT": "Portugal",
    "PW": "Palau",
    "PY": "Paraguai",
    "QA": "Catar",
    "RE": "Reunião",
    "RO": "Roménia",
    "RS": "Sérvia",
    "RU": "Rússia",
    "RW": "Ruanda",
    "SA": "Arábia Saudita",
    "SB": "Ilhas Salomão",
    "SC": "Seicheles",
    "SD": "Sudão",
    "SE": "Suécia",
    "SG": "Singapura",
    "SI": "Eslovênia",
    "SK": "Eslováquia",
    "SL": "Serra Leoa",
    "SM": "São Marinho",
    "SN": "Senegal",
    "SV": "El Salvador",
    "SY": "Síria",
    "SZ": "Suazilândia",
    "TC": "Ilhas Turcas e Caicos",
    "TD": "Chade",
    "TG": "Ir",
    "TH": "Tailândia",
    "TJ": "Tajiquistão",
    "TK": "Toquelau",
    "TL": "Timor Leste",
    "TM": "Turquemenistão",
    "TN": "Tunísia",
    "TO": "Tonga",
    "TR": "Peru",
    "TT": "Trindade e Tobago",
    "TV": "Tuvalu",
    "TW": "Taiwan",
    "TZ": "Tanzânia",
    "UA": "Ucrânia",
    "UG": "Uganda",
    "US": "Estados Unidos da América",
    "CD": "Congo, Dem. Rep. of (Zaire)",
    "UY": "Uruguai",
    "UZ": "Uzbequistão",
    "VE": "Venezuela",
    "VG": "Ilhas Virgens Britânicas",
    "VI": "Ilhas Virgens Americanas",
    "VN": "Vietname",
    "VU": "Vanuatu",
    "WF": "Wallis e Futuna",
    "WS": "Samoa",
    "YE": "Iémen",
    "YT": "Maiote",
    "ZA": "África do Sul",
    "ZM": "Zâmbia",
    "ZW": "Zimbabué",
    "AX": "Ilhas Aland",
    "BQ": "Caribe Holanda",
    "EA": "Ceuta e Melilha",
    "EH": "Saara Ocidental",
    "GG": "Guernsey",
    "IC": "Ilhas Canárias",
    "IM": "Ilha de Man",
    "JE": "Camisa",
    "MZ": "Moçambique",
    "PN": "Ilhas Pitcairn",
    "SO": "Somália",
    "SS": "Sudão do Sul",
    "SX": "Sint Maarten",
    "TA": "Tristão da Cunha",
    "TF": "Territórios Franceses do Sul",
    "VA": "Cidade do Vaticano",
    "XK": "Kosovo",
    "AC": "Ilha de Ascensão",
    "CC": "Ilhas Cocos (Keeling)",
    "SR": "Suriname",
    "DG": "Diego Garcia",
    "GB": "Reino Unido"
  },
  "footer_links": {
    "about_us": "Sobre",
    "faq": "Perguntas frequentes",
    "privacy_policy": "Privacidade",
    "terms_of_service": "Termos",
    "custom": "{custom}"
  },
  "labels": {
    "access_code": "Digite o código de acesso",
    "active": "Ativo",
    "all": "Tudo",
    "all_results": "Todos os resultados",
    "amount": "Quantia",
    "anytime": "A qualquer momento",
    "bookmark_add": "Adicionar marcador",
    "bookmark_remove": "Remover marcador",
    "clip": "Clipe | \nClipes",
    "commerce_category_labels": {
      "free": "Grátis",
      "member": "Membro",
      "ppv": "Pagar para ver",
      "premium": "Prêmio",
      "rental": "Alugar",
      "subscription": "Subscrever"
    },
    "lock_event_message": {
      "member": "Este evento só está disponível para Membros.",
      "geo_blocking": "Lamentamos, mas o vídeo que você está tentando reproduzir está restrito à sua região",
      "event_restricted": "Este evento é restrito para sua localização",
      "premium": "Subscreva para Assistir",
      "subscription": ""
    },
    "confirm_new_password": "Confirme a nova palavra-passe",
    "continue": "Continuar",
    "continue_with": "Continuar com {provider}",
    "country": "País",
    "create_highlight": "Criar destaque",
    "dark": "Escuro",
    "date": "Data",
    "date_added": "Data adicionada:",
    "days": "Dias",
    "email": "E-mail",
    "enter_new_password": "Insira a nova palavra-passe",
    "event_date": "Data do evento",
    "events": "Eventos",
    "follow": "Seguir",
    "following": "Deixar de segui",
    "forgot_password": "Esqueceu-se da palavra-passe",
    "full_name": "Nome completo",
    "game_highlights": "Destaques do jogo",
    "game_videos": "Vídeos do jogos",
    "highlights": "Destaques",
    "hours": "Horas",
    "latest": "Mais recentes",
    "latest_events": "Últimos eventos",
    "latest_highlights": "Últimos destaques",
    "light": "Claro",
    "live": "Em direto",
    "load_more": "Carregar mais",
    "main": "Principal",
    "minutes": "Minutos",
    "modal_labels": {
      "delete_clip": "Tem certeza de que deseja remover este clipe?",
      "delete_highlight": "Tem certeza de que deseja remover este destaque?",
      "delete_profile": "Tem certeza de que deseja eliminar seu perfil?",
      "delete_tag": "Tem certeza de que deseja remover esta etiqueta?"
    },
    "newest": "Recentes",
    "next": "Próximo",
    "oldest": "Antigos",
    "or": "ou",
    "password": "Palavra-passe",
    "password_new": "Nova palavra-passe",
    "password_old": "Palavra-passe Antiga",
    "password_repeat": "Repita a palavra-passe",
    "past_30_days": "Últimos 30 dias",
    "past_7_days": "Últimos 7 dias",
    "past_90_days": "Últimos 90 dias",
    "phone": "Telefone",
    "player_highlights": "Destaques do jogador",
    "policy": "Política de Privacidade",
    "powered_by": "Distribuído por",
    "ppv_price": "Assista a partir de {price}",
    "purchase": "Comprar",
    "save": "Guardar",
    "search": "Procurar",
    "search_input_placeholder": "Pesquise escolas, clubes, ligas etc.",
    "seconds": "Segundos",
    "see_all": "Ver todos >",
    "share": "Partilhar",
    "sign_in": "Entrar",
    "sign_up": "Inscrever-se",
    "skip": "Voltar",
    "sport_type": "Tipo de desporto",
    "statistics": "Estatísticas",
    "submit": "Submeter",
    "tag": "Etiqueta | \nTag",
    "terms": "Termos de serviço",
    "today": "Hoje",
    "total": "Total",
    "type": "Modelo",
    "upcoming": "Brevemente",
    "update": "Atualizar",
    "views": "sem visualizações | \n{count} ver | \n{count} visualizações",
    "watch_full_game": "Assistir ao jogo completo",
    "yesterday": "Ontem",
    "your_clips_highlight": "Seus clipes/destaques",
    "access_time": "Você terá acesso a",
    "full_access": "Acesso total",
    "limited_access": "Acesso limitado",
    "ppv_tickets": "bilhetes PPV",
    "release_date": "Data de lançamento",
    "subscriptions": "Assinaturas",
    "months": "Meses | \nmês | \nmeses",
    "weeks": "Semanas | \nsemana | \nsemanas",
    "years": "Anos | \nano | \nanos",
    "change_product": "Alterar produto",
    "go_to_event": "Ir para o evento",
    "go_to_account": "Ir para conta",
    "go_to_main_page": "Ir para a página principal",
    "name": "Nome",
    "update_card": "cartão de atualização",
    "page_not_found": "página não encontrada",
    "inactive": "Inativo",
    "my_club": "meu clube",
    "select_your_club": "Selecione seu clube",
    "add_to_selected": "Adicionar aos selecionados",
    "remove_from_selected": "Remover dos selecionados",
    "password_not_match": "As senhas não correspondem",
    "search_by_sport_type": "Pesquise por tipo de esporte",
    "videos": "Vídeos",
    "category": "Categoria",
    "reset_password": "Por favor, digite sua nova senha",
    "min_8_characters": "Mínimo de 8 caracteres",
    "league": "Liga",
    "here": "Aqui",
    "suggested": "Sugerido",
    "enter_old_password": "Digite a palavra-passe antiga",
    "less": "Menos",
    "more": "Mais",
    "durationRenew": "Duração: será renovada {duration} {units} após a compra | \nDuração: será renovado em {duration}",
    "duration": "Duração: expira {duration} {units} após a compra | \nDuração: expira {duration}",
    "team": "Equipe",
    "team_1_logo": "Logotipo da Equipe 1",
    "team_2_logo": "Logotipo da Equipe 2",
    "stats": "Estatísticas",
    "gameType_labels": {
      "game": "Jogo",
      "other": "Outro",
      "practice": "Prática",
      "training": "treinamento"
    },
    "fullName": "Nome completo",
    "archive": "Arquivo",
    "panoramic_mode": "Você está no modo panorâmico",
    "postal_code": "Código postal",
    "search_event_league_team": "Pesquise um evento, competição ou equipa",
    "back_to_site": "Voltar ao site",
    "done": "Concluído",
    "search_teams": "Procurar equipas",
    "game_recap": "Resumo do jogo",
    "play_by_play": "Jogo condensado",
    "your_clips": "Seus clipes"
  },
  "locales": {
    "en": "English",
    "de": "German",
    "es": "Spanish",
    "pt": "Portuguese",
    "uk-UA": "Ukrainian",
    "pl": "Polish",
    "fr": "French",
    "he": "Hebrew",
    "it": "Italian",
    "title": "Escolha o idioma do site",
    "ca": "Catalan",
    "ko": "Korean",
    "zh": "Chinese",
    "bs": "Lokalni"
  },
  "not-found": "Não encontrado",
  "pages": {
    "account": {
      "account_delete_button": "Apagar conta",
      "account_delete_confirm": "Tem certeza de que deseja excluir seu perfil?",
      "account_delete_description": "Esta ação não pode ser revertida.",
      "tab_billing_title": "Informações de pagamento",
      "tab_devices_title": "Dispositivos",
      "tab_general_title": "Geral",
      "tab_password_title": "Palavra-passe",
      "tab_subscription_title": "Subscrições",
      "tab_ppv_access_text": "O bilhete PPV dá acesso a",
      "tab_ppv_no_ppv": "Você não tem tickets PPV. \nAcesse a página do evento para comprar um ingresso.",
      "tab_ppv_title": "Código PPV",
      "account_delete_successfully": "Sua conta foi deletada com sucesso!",
      "account_update_successfully": "Sua conta foi atualizada com sucesso!"
    },
    "account_billing": {
      "alert_credit_card_save_success": "As informações do seu cartão de crédito foram guardadas com sucesso",
      "alert_credit_card_update_success": "As informações do seu cartão de crédito foram atualizadas com sucesso",
      "button_back_to_billing_info": "Voltar para as informações de facturação",
      "card_cvv": "CVC/CVV",
      "card_expiry_date": "Data de validade",
      "card_holder_name": "Nome no cartão",
      "card_number": "Número do cartão",
      "label_modal_confirm_credit_card_delete": "Tem certeza de que deseja excluir este cartão 7 pagamento?",
      "payment_history_empty_text": "O seu histórico de pagamentos está vazio",
      "payment_history_title": "Histórico de pagamentos",
      "alert_credit_card_delete_success": "Seu cartão de cobrança foi excluído com sucesso",
      "payment_in_progress": "Pagamento em andamento",
      "payment_in_progress_message": "Não feche esta página até que seu pagamento seja processado."
    },
    "account_password": {
      "alert_password_update_success": "A sua palavra-passe foi alterada com sucesso"
    },
    "account_subscription": {
      "active_subscription": "Assinatura ativa",
      "alert_access_code_redeem_code_used": "Código de acesso solicitado já usado",
      "alert_access_code_redeem_not_found": "O código de acesso solicitado não existe",
      "alert_access_code_redeemed_success": "Código de acesso resgatado com sucesso",
      "alert_subscription_cancel_success": "Assinatura cancelada com sucesso",
      "button_back_to_my_subscription": "Voltar para minha assinatura",
      "button_subscribe_now": "Inscreva-se agora",
      "button_subscription_cancel": "Cancelar assinatura",
      "label_plan_amount": "Valor do plano",
      "label_taxes": "Impostos",
      "label_total_billed": "Total faturado",
      "or_select_plan": "ou selecione o seu plano",
      "redeem_access_code_label": "Resgate o seu Código de Acesso aqui",
      "subscription_due_date": "Data de Vencimento",
      "subscription_expiration_date": "Expirado por",
      "subscription_last_payment_date": "Data do último pagamento",
      "subscription_next_charge_date": "Próxima cobrança",
      "tab_all_subscriptions": "Todas as assinaturas",
      "tab_my_subscriptions": "Minhas assinaturas",
      "label_full_access": "Acesso total",
      "label_full_access_description": "Acesso total a todo o conteúdo do site",
      "label_limited_access": "Acesso limitado",
      "label_limited_access_description": "Acesso limitado a todo o conteúdo do site",
      "subscription_expires": "A assinatura expira em {date}",
      "payment_details": "{title}: detalhes de pagamento",
      "no_subscriptions_text": "Não há assinaturas ativas na lista.\n\nVolte mais tarde",
      "redeem_access_code_title": "Resgatar seu Código de Acesso",
      "button_back_to_my_tickets": "Voltar para meus ingressos",
      "list_subscriptions_empty": "A lista de assinaturas disponíveis está vazia. \nVolte mais tarde",
      "button_subscription_keep": "Manter assinatura",
      "cancel_subscription_modal_subtitle": "Sua assinatura será cancelada ao final do período de cobrança em {date}. \nVocê pode mudar de ideia a qualquer momento antes dessa data.",
      "cancel_subscription_modal_title": "Tem certeza que deseja cancelar sua assinatura?",
      "checkbox_apply_as_new_credit_card": "Salvar detalhes do cartão",
      "renew_subscription_expires": "A assinatura é recorrente. \nData da próxima cobrança {date}"
    },
    "auth_login": {
      "device_no_longer_allowed_error": "O Seu dispositivo atual não está mais na lista de dispositivos permitidos. \nPor favor faça login novamente."
    },
    "auth_login_limit": {
      "current_device": "Dispositivo atual",
      "device_limit_reached": "Limite de dispositivos atingido",
      "remove_device_to_login": "Remova um dispositivo para fazer login"
    },
    "auth_password": {
      "back_to_sign_in": "Voltar a fazer login",
      "forgot_password_help_text": "Vamos ajudá-lo a redefini-lo e voltar aos trilhos.",
      "forgot_password_success_text": "Se existir uma conta para {email}, enviaremos instruções para redefinir a sua palavra-passe. \nNão os pegou? \nVerifique o endereço de e-mail ou peça para reenviar a instrução",
      "resend_instruction": "Reenvie a instrução novamente",
      "send_reset_link": "Enviar link de redefinição",
      "set_new_password": "Definir nova palavra-passe"
    },
    "auth_sign_in": {
      "auth_terms": "Ao continuar, declara que concorda com os nossos {terms_of_service} e {privacy_policy}",
      "new_to": "Novo utilizador",
      "welcome_message": "Bem vindo de volta",
      "sign_in_with_provider": "Faça login com suas credenciais de {provider}"
    },
    "auth_sign_up": {
      "already_have_account": "já tem uma conta?",
      "enter_your_details_below": "Insira os seus dados abaixo",
      "follow_category_header": "Selecione as categorias que deseja seguir",
      "password_updated_successuly_text": "A sua palavra-passe foi atualizada com sucesso",
      "back_to_all_teams": "Voltar",
      "follow_teams_header": "Selecione as equipes que deseja seguir",
      "back_to_all_categories": "Voltar para todas as categorias"
    },
    "clips": {
      "title_no_clips": "Ainda não tem clipes guardados. \nAssista ao vídeo e crie os seus primeiros clipes!",
      "title_no_highlights": "Ainda não guardou nenhum destaque. \nAssista ao vídeo e crie os seus primeiros destaques!",
      "title_no_items_with_selected_filter": "Nenhum item encontrado com este filtro"
    },
    "clubs": {
      "manage_following": "A seguir",
      "choose_your_club": "Escolha seu clube",
      "choose_your_club_message": "Escolha o clube ao qual você será associado"
    },
    "events": {
      "event_start_date": "O evento começará em {date}",
      "failed_statistics": "Falha ao carregar estatísticas relacionadas a este evento. Tente novamente mais tarde.",
      "game_video_login_message": "Faça login para ver os vídeos do jogo",
      "modal_share_title": "Compartilhe este vídeo",
      "modal_share_with_time": "Comece às {time}",
      "tabs": {
        "event_tags": {
          "choose_player_color": "Escolha um jogador '",
          "choose_tag": "Escolha o tipo de etiqueta",
          "create_tag": "Criar etiqueta",
          "make_tag_global": "Tornar a etiqueta global",
          "filters": {
            "by_player_number": "Jogador'",
            "by_type": "Por tipo",
            "clear_all_btn": "Limpar tudo | \nLimpar tudo ({count}) | \nLimpar tudo ({count})",
            "no_tags": "Nenhuma tag correspondente ao filtro selecionado",
            "popup_title": "Filtro",
            "search_input_placeholder": "Pesquise tags específicas",
            "show_results_btn": "Mostrar resultados",
            "select_all": "Selecione tudo | \nSelecione tudo ({count}) | \nSelecione tudo ({count})",
            "by_team": "Por equipe"
          },
          "is_external_content": "Este é um conteúdo externo, você não pode criar uma tag",
          "name_the_tag": "Dê um nome à etiqueta",
          "tag_creating_successfully_text": "A etiqueta foi criada com sucesso",
          "title_no_tags": "Ainda não há etiquetas na lista, seja o primeiro a adicionar novas",
          "title_no_tags_not_logged_in": "Ainda não há etiquetas na lista, faça login para criar novas etiquetas",
          "tag_deleting_successfully_text": "A etiqueta foi excluída com sucesso",
          "tag_updating_successfully_text": "A etiqueta foi atualizada com sucesso",
          "title_enabled_tagging": "Não há tags na lista",
          "update_tag": "Atualizar etiqueta",
          "pause_all_btn": "Pausar tudo",
          "play_all_btn": "Jogar tudo",
          "choose_team": "Escolha uma equipe"
        },
        "event_videos": {
          "clip_creating_successfully_text": "O Seu clipe estará pronto em breve",
          "create_clip": "Criar clipe",
          "name_the_clip": "Dê um nome ao clipe",
          "title_enabled_clipping": "Não há clipes na lista",
          "title_no_clips": "Ainda não há clipes na lista, seja o primeiro a adicionar novos",
          "title_no_clips_not_logged_in": "Ainda não há clipes na lista, faça login para criar novos clipes",
          "clip_deleted_successfully_text": "Clipe eliminado",
          "rename_clip": "Renomear clipe",
          "clip_updating_successfully_text": "Clipe Atualizado"
        },
        "event_stats": {
          "empty_stats_data": "Os dados estatísticos estão vazios",
          "game_board_title": "Estatísticas do jogo",
          "score_board_title": "Pontuação"
        }
      },
      "tags_login_message": "Faça login para visualizar as tags",
      "viewing_conditions": "Escolha as suas condições de visualização",
      "appreciation": "Obrigada!",
      "success_purchased": "Você comprou {subscription} com sucesso",
      "failed_purchased_message": "Tente novamente ou insira outro cartão.",
      "purchased_declined": "O pagamento foi recusado",
      "watching_event_by_text": "Você está assistindo a este evento por",
      "failed_code_applied_message": "Tente novamente ou insira outro código.",
      "failed_code_applied_title": "Código inválido",
      "success_code_applied": "Código {title} aplicado com sucesso",
      "submit_reedem_code_input_placeholder": "Insira seu código de acesso aqui",
      "failed_already_used_code": "Este código já foi usado",
      "event_will_start_in_a_few_min": "O evento começará automaticamente em alguns minutos...",
      "success_purchased_secondary": "Sua compra foi bem sucedida",
      "time_till_kick_off": "Tempo até o início:",
      "failed_purchased_message_2": "Tente novamente ou use outro método de pagamento."
    },
    "league": {
      "all_rounds": "Todas as Jornadas"
    },
    "videos": {
      "description_no_items": "Este é o local para gerir os vídeos que selecionou. \nGuarde, reveja e partilhe!",
      "last_viewed": "Visto pela última vez",
      "title_no_items": "Adicione alguns vídeos aos guardados",
      "user_interesting": "Também poderá ter interesse"
    },
    "club": {
      "favorite_club_creating_successfully_text": "Seu clube foi escolhido com sucesso"
    },
    "categories": {
      "no_following_category_for_logged_user": "Clique {here} para seguir equipas",
      "no_following_category_for_unlogged_user": "Faça {login} para se manter atualizado com seus eventos favoritos"
    }
  },
  "sport_types": {
    "americanFootball": "Futebol americano",
    "badminton": "Badmínton",
    "beachvolleyball": "Vôlei de praia",
    "fieldhockey": "Hóquei em campo",
    "icehockey": "Hockey no gelo",
    "rollerhockey": "Hóquei em patins",
    "baseball": "Beisebol",
    "basketball": "Basquetebol",
    "bowling": "Bowling",
    "cricket": "Críquete",
    "floorball": "Floorball",
    "futsal": "Futsal",
    "gymnastics": "Ginástica",
    "handball": "Andebol",
    "lacrosse": "Lacrosse",
    "netball": "Netbol",
    "other": "Outro",
    "rugby": "Râguebi",
    "snooker": "Snooker",
    "soccer": "Futebol",
    "softball": "Softebol",
    "swimming": "Natação",
    "tennis": "Ténis",
    "volleyball": "Voleibol",
    "waterpolo": "Pólo aquático",
    "wrestling": "Luta livre",
    "football": "Futebol"
  },
  "errors": {
    "expired_session_error": "Sua sessão expirou. \nPor favor faça login novamente.",
    "vod_files_not_found": "Ops! \nArquivos de vídeo não encontrados",
    "vod_playback_failed": "Ops! \nA reprodução falhou durante a transmissão VOD",
    "could_not_find_event": "Desculpe, não encontramos o evento que você está procurando.",
    "could_not_find_page": "Desculpe, não encontramos a página que você está procurando.",
    "could_not_find_team": "Desculpe, não encontrámos a equipa.",
    "event_not_found": "Evento não encontrado",
    "page_not_found": "página não encontrada",
    "team_not_found": "A equipa não foi encontrada",
    "could_not_find_results": "Desculpe, não encontramos nenhum resultado para esta pesquisa.",
    "ERR400CL11": "Falha ao criar clipe",
    "ERR400CM11": "Pagamento falhou",
    "ERR400CM12": "Falha ao fazer o pedido",
    "ERR400CM13": "Falha no pagamento da captura",
    "ERR400CM14": "As informações do comerciante ao salvar a assinatura falharam",
    "ERR400CM15": "Verifique as informações do seu cartão de crédito",
    "ERR400CM16": "Valor de plataforma inválido fornecido",
    "ERR400GN00": "Pedido ruim",
    "ERR400GN11": "ID inválido fornecido",
    "ERR400GN12": "Campos não atualizáveis ​​fornecidos",
    "ERR400ID11": "Identidade inválida fornecida",
    "ERR400ID12": "Item de identidade inválido fornecido",
    "ERR400ID13": "A identidade não pode ser adicionada à lista do clube",
    "ERR400ID14": "Par inválido de identidade e item de identidade fornecido",
    "ERR400TG11": "Falha ao criar etiqueta",
    "ERR400UR11": "Nome e sobrenome do perfil são obrigatórios",
    "ERR400UR12": "O e-mail é obrigatório",
    "ERR400UR13": "O usuário já possui um cartão de crédito armazenado",
    "ERR400UR14": "Preencha os campos de nome, sobrenome e e-mail do seu perfil antes de salvar um cartão de crédito",
    "ERR400UR17": "O usuário não tem cartão de crédito para editar",
    "ERR400UR18": "A assinatura já foi cancelada",
    "ERR400UR19": "A assinatura não está ativa",
    "ERR400UR20": "Nome de usuário e senha são obrigatórios",
    "ERR400UR21": "A conta não está ativa",
    "ERR401AT11": "O token é obrigatório",
    "ERR401AT12": "Token de redefinição de senha inválido",
    "ERR401AT13": "Token de atualização inválido",
    "ERR401AT14": "Emissor de assunto desconhecido",
    "ERR401AT15": "Tipo de token de entidade incompatível",
    "ERR401AT16": "Token inválido",
    "ERR401AT17": "Token expirado",
    "ERR401AT18": "Falha ao obter um e-mail do token",
    "ERR401AT19": "Usuário não encontrado",
    "ERR401GN00": "Não autorizado",
    "ERR401UR11": "O nome de usuário ou senha está errado. \nVerifique suas credenciais.",
    "ERR402GN00": "Pagamento Requerido",
    "ERR403GN00": "Proibido",
    "ERR403TG11": "O usuário não pode atualizar tags globais",
    "ERR403TG12": "O usuário não pode criar tags globais",
    "ERR403TG13": "O usuário não pode excluir as tags que não foram criadas por um",
    "ERR403TG14": "O usuário não pode excluir tags globais",
    "ERR404CM11": "Não é possível obter assinaturas",
    "ERR404CM12": "Código de acesso não encontrado",
    "ERR404EV11": "Não é possível obter informações do evento",
    "ERR404EV12": "Evento ou VOD não encontrado",
    "ERR404GN00": "Não encontrado",
    "ERR404ID00": "Identidade não encontrada",
    "ERR404ID11": "A identidade não pôde ser recuperada",
    "ERR404PR00": "Produto não encontrado",
    "ERR404PR11": "Não é possível obter informações de ppv",
    "ERR404TG00": "Marca não encontrada",
    "ERR404TM00": "Equipe não encontrada",
    "ERR404UR00": "Usuário não encontrado",
    "ERR404UR11": "Pedido não encontrado",
    "ERR404UR12": "A assinatura não foi encontrada",
    "ERR404UR13": "Usuário não existe",
    "ERR404UR14": "O perfil não existe",
    "ERR404UR15": "O cartão não foi encontrado para este usuário",
    "ERR404UR16": "Vídeo não encontrado na lista de observação",
    "ERR404VD11": "Vod não pôde ser recuperado",
    "ERR409CM11": "Código de acesso já em uso",
    "ERR409GN00": "Conflito",
    "ERR409PR12": "Produto já comprado",
    "ERR409UR11": "O clube já está adicionado",
    "ERR409UR12": "Usuário já cadastrado",
    "ERR422GN00": "Conteúdo não processável",
    "ERR451GN00": "Indisponível por motivo legal",
    "ERR500GN00": "Erro do Servidor Interno",
    "ERR500NT12": "O envio do e-mail de redefinição de senha falhou",
    "ERR501GN00": "Não implementado",
    "ERR502GN00": "Gateway inválido",
    "ERR503GN00": "Serviço não disponível",
    "ERR504GN00": "Tempo limite do gateway",
    "could_not_find_league": "Desculpe, não encontramos a liga que você está procurando.",
    "default_error_message": "Ocorreu um erro no servidor. \nPor favor, tente novamente mais tarde",
    "device_limit_exceeded": "Limite do dispositivo excedido!",
    "league_not_found": "Liga não encontrada",
    "vod_corrupted_files": "O vídeo não pôde ser carregado",
    "passwords_dont_match": "As senhas não correspondem",
    "incomplete_cvc": "O código de segurança do seu cartão está incompleto!",
    "incomplete_expiry": "A data de validade do seu cartão está incompleta!",
    "incomplete_number": "O número do seu cartão está incompleto!",
    "invalid_expiry_month_past": "O ano de validade do seu cartão está no passado!",
    "invalid_expiry_year": "O ano de validade do seu cartão é inválido!",
    "invalid_expiry_year_past": "O ano de validade do seu cartão está no passado!",
    "invalid_number": "O número do seu cartão é inválido!",
    "popup_closed": "O pop-up foi fechado",
    "login_failed": "falha no login",
    "ERR400UR24": "A senha antiga está errada. \nPor favor, verifique sua credencial"
  },
  "tags": {
    "baseball_bottom_1st": "1º inferior",
    "baseball_bottom_2nd": "inferior 2º",
    "baseball_double": "dobro",
    "baseball_double_play": "jogo duplo",
    "baseball_fly_out": "voar para fora",
    "baseball_ground_out": "aterrar",
    "baseball_hit": "bater",
    "baseball_home_run": "para fora",
    "baseball_popout": "popout",
    "baseball_run": "correr",
    "baseball_single": "solteiro",
    "baseball_stolen_base": "base roubada",
    "baseball_strikeout": "riscado",
    "baseball_substitution": "substituição",
    "baseball_top_1st": "primeiro lugar",
    "baseball_top_2nd": "2º lugar",
    "baseball_triple_play": "jogada Tripla",
    "baseball_tripple": "triplo",
    "baseball_walk": "andar",
    "basketball_alley_oop": "beco oop",
    "basketball_assist": "ajudar",
    "basketball_big_play": "grande jogada",
    "basketball_block": "bloquear",
    "basketball_dunk": "afundar",
    "basketball_momentym": "impulso",
    "basketball_money_time": "dinheiro tempo",
    "basketball_substitution": "substituição",
    "basketball_three_point": "três pontos",
    "beach_volleyball_dig": "escavação",
    "beach_volleyball_double": "dobro",
    "beach_volleyball_free_ball": "bola livre",
    "beach_volleyball_match_point": "ponto que decide o jogo",
    "beach_volleyball_mishit": "errar",
    "beach_volleyball_serve": "servir",
    "beach_volleyball_set_point": "ponto de ajuste",
    "beach_volleyball_shank": "haste",
    "beach_volleyball_side_out": "lado para fora",
    "beach_volleyball_spike": "espinho",
    "beach_volleyball_substitution": "substituição",
    "field_ball_loss": "perda de bola",
    "field_ball_possesion": "posse de bola",
    "field_circle_penetration": "penetração do círculo",
    "field_hockey_4v4": "4v4",
    "field_hockey_big_hit": "grande golpe",
    "field_hockey_fight": "lutar",
    "field_hockey_goal": "meta",
    "field_hockey_other": "outro",
    "field_hockey_power_play": "jogo de poder",
    "field_hockey_save": "salvar",
    "field_hockey_shot": "tomada",
    "field_hockey_substitution": "substituição",
    "field_interception": "interceptação",
    "field_penalty_corner": "pênalti",
    "field_press_fp_hc": "imprensa",
    "field_time_penalty": "penalidade de tempo",
    "field_turn_over": "virar",
    "football_big_catch": "grande captura",
    "football_big_hit": "grande golpe",
    "football_big_run": "grande corrida",
    "football_field_goal": "gol de campo",
    "football_kick_return": "chute de retorno",
    "football_kickoff": "Início de Jogo",
    "football_punt_return": "punt retorno",
    "football_substitution": "substituição",
    "football_touch_down": "tocar para baixo",
    "futsal_corner_kick": "Escanteio",
    "futsal_foul": "falta",
    "futsal_free_kick": "tiro livre",
    "futsal_goal": "meta",
    "futsal_header": "cabeçalho",
    "futsal_penalty": "pena",
    "futsal_redcard": "cartão vermelho",
    "futsal_save": "salvar",
    "futsal_shot": "tomada",
    "futsal_shot_on_target": "tiro no alvo",
    "futsal_substitution": "substituição",
    "futsal_yellowcard": "cartão amarelo",
    "general_bad": "ruim",
    "general_drill": "furar",
    "general_good": "bom",
    "gymnastic_wow": "uau",
    "handball_foul": "falta",
    "handball_goal": "meta",
    "handball_penalty_shot": "pênalti",
    "handball_red_card": "cartão vermelho",
    "handball_save": "salvar",
    "handball_shot_on_goal": "tiro a gol",
    "handball_substitution": "substituição",
    "handball_suspension": "suspensão",
    "handball_yellow_card": "cartão amarelo",
    "hockey_4v4": "4v4",
    "hockey_big_hit": "grande golpe",
    "hockey_fight": "lutar",
    "hockey_goal": "meta",
    "hockey_power_play": "jogo de poder",
    "hockey_save": "salvar",
    "hockey_shot": "tomada",
    "hockey_substitution": "substituição",
    "lacrosse_clear": "claro",
    "lacrosse_face_off": "se enfrentam",
    "lacrosse_goal": "meta",
    "lacrosse_ground_ball": "bola de chão",
    "lacrosse_man_up": "homem para cima",
    "lacrosse_penalty": "pena",
    "lacrosse_save": "salvar",
    "lacrosse_shot": "tomada",
    "lacrosse_substitution": "substituição",
    "other_wow": "outro uau",
    "roller_ball_loss": "perda de bola",
    "roller_ball_possesion": "posse de bola",
    "roller_circle_penetration": "penetração do círculo",
    "roller_hockey_4v4": "4v4",
    "roller_hockey_big_hit": "grande golpe",
    "roller_hockey_fight": "lutar",
    "roller_hockey_goal": "meta",
    "roller_hockey_other": "outro",
    "roller_hockey_power_play": "jogo de poder",
    "roller_hockey_save": "salvar",
    "roller_hockey_shot": "tomada",
    "roller_hockey_substitution": "substituição",
    "roller_interception": "interceptação",
    "roller_penalty_corner": "canto",
    "roller_press_fp_hc": "imprensa",
    "roller_time_penalty": "penalidade de tempo",
    "roller_turn_over": "virar",
    "rugby_break_down": "discriminação",
    "rugby_conversion": "conversão",
    "rugby_drop_goal": "derrubar meta",
    "rugby_garry_owen": "garry owen",
    "rugby_grubber": "grubber",
    "rugby_knock_on": "bater na",
    "rugby_maul": "malho",
    "rugby_penalty_try": "tentativa de pênalti",
    "rugby_substitution": "substituição",
    "soccer_corner_kick": "Canto",
    "soccer_foul": "Falta",
    "soccer_free_kick": "Livre",
    "soccer_goal": "Golo",
    "soccer_header": "Cabeceamento",
    "soccer_penalty": "Penálti",
    "soccer_redcard": "Cartão Vermelho",
    "soccer_save": "Defesa",
    "soccer_shot": "Remate",
    "soccer_shot_on_target": "Remate à baliza",
    "soccer_substitution": "Substituição",
    "soccer_yellowcard": "Cartão amarelo",
    "volleyball_dig": "escavação",
    "volleyball_double": "dobro",
    "volleyball_free_ball": "bola livre",
    "volleyball_match_point": "ponto que decide o jogo",
    "volleyball_mishit": "errar",
    "volleyball_serve": "servir",
    "volleyball_set_point": "ponto de ajuste",
    "volleyball_shank": "haste",
    "volleyball_side_out": "lado para fora",
    "volleyball_spike": "espinho",
    "volleyball_substitution": "substituição",
    "wrestling_wow": "uau"
  }
}
